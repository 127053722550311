ul {
    list-style: none;
  } 


  .education--item-img > img {
    border-radius: 8px;
    height: 100%;
    width: 100%;
  }
  
.section--heading {
    font-size: 50px;
    font-weight: 700;
    line-height: 90px;
  }


  .education-table-container {
    font-family: Arial, sans-serif;
    color: var(--darkblue);
    margin: 15px;
  }
  
  .education-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .education-table th, .education-table td {
    padding: 5px;
    align-items: center;
    text-align: center;
    font-size: 18px;
  }
  
  .education-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
    align-self: stretch;
  }
  
  
  .education-table tbody tr:hover {
    background-color: #f1f1f1;
  }

  .education--item-img > img {
    border-radius: 20px;
    height: 100%;
    width: 100%;
  }
  
  .education--item-description {
    gap: 10px;
    width: 500px;
  }

  /* Prevent wrapping for specific columns */
  .education-table td:nth-child(2),
  .education-table td:nth-child(3) {
    padding-right: 20px;
    padding-left: 20px;
  }
  .education-table td:nth-child(4),
  .education-table td:nth-child(5) {
    white-space: nowrap;
    padding-right: 20px;
    padding-left: 20px;
  }
  
.education-table td:nth-child(1) {
  padding-right: 20px;
  padding-left: 20px;
  width: 150px;
}

@media screen and (max-width: 1200px) {
  .education-table-container {
    overflow-x: auto;
  }
  .education-table {
    width: auto;
  }
  .education-table th, .education-table td {
    padding-right: 50px;
    font-size: 16px;
  }

  .education-table td:nth-child(2),
  .education-table td:nth-child(3),
  .education-table td:nth-child(4),
  .education-table td:nth-child(5) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .education--item-img > img {
    border-radius: 20px;
    height: 100px;
    width: 100px;
  }
}